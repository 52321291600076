<script>
import { SuawMainContent, SuawSection, SuawSidebar, SuawDivider } from "@suaw/suaw-component-library";
import SideNav from "../components/SideNav.vue";
import WriteWithUs from "../components/WriteWithUs.vue";

export default {
  name: "ResourceFrame",
  components: {
    SuawMainContent,
    SuawSection,
    SuawSidebar,
    SuawDivider,
    SideNav,
    WriteWithUs
  },
  data() {
    return {
      isSidebarVisible: window.innerWidth >= 1025
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSidebarVisible = window.innerWidth >= 1025;
    }
  }
};
</script>

<template>
  <SuawMainContent size="large" :show-gradient="true">
    <SuawSection>
      <slot />
      <template v-if="isSidebarVisible">
        <SuawSidebar>
          <SideNav />
        </SuawSidebar>
      </template>
    </SuawSection>
    <SuawDivider />
    <SuawSection>
      <WriteWithUs />
    </SuawSection>
  </SuawMainContent>
</template>
