<script>
import { SuawSideNav } from "@suaw/suaw-component-library";
export default {
  name: "SideNav",
  components: { SuawSideNav },
  data() {
    return {
      links: [
        { to: "/resources/about", name: "About Shut Up & Write!" },
        { to: "/resources/method", name: "Our Method" },
        { to: "/resources/our-organization", name: "Our Organization" },
        { to: "/resources/impact", name: "Our Impact" },
        { to: "/resources/get-involved", name: "Get Involved" },
        { to: "/resources/code-of-conduct", name: "Code of Conduct" },
        { to: "/resources/faq", name: "FAQ" }
      ]
    };
  },
  methods: {
    async copyLink() {
      try {
        const url = window.location.origin + this.$route.fullPath;
        await navigator.clipboard.writeText(url);
        this.$root.$emit("universal-success-message", "Link copied to clipboard successfully!");
      } catch (err) {
        this.$root.$emit("universal-error-message", "Failed to copy link to clipboard.");
      }
    }
  }
};
</script>

<template>
  <SuawSideNav :links="links" @click="copyLink" />
</template>
